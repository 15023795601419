import React from "react"
import { graphql } from "gatsby"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import ApiList from "../components/ApiList"
import Header from "../components/Header"
import PageWrapper from "../PageWrapper"
import heroSvg from "../img/undraw/undraw_online_organizer_ofxm.svg"
import PAGEDATA from "../../content/api.json"

function Hero({ bgImage }){

    return (
        <>
            <div className="header-hero bg_cover d-lg-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="header-hero-content">
                                <h1 className="hero-title"><b>LEXIQA API</b></h1>
                                <p className="hero-subtitle mt-3">lexiQA exposes its data via an Application Programming Interface (API), so developers can interact in a programmatic way with the lexiQA application.</p>
                                <br/>
                                <p>The API calls are REST-compliant and make use of the most common HTTP methods</p>
                                <br/>
                                <p><b>Below you can find a sample of these API calls. In order to have access to the entire API documentation, please contact us.</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-hero-image d-flex align-items-center">
                    <div className="image">
                        <img src={bgImage} alt="Hero" />
                    </div>
                </div>
            </div>
        </>

    );

}

const ApiIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header component={<Hero bgImage={heroSvg} />} title={siteTitle} />
      <SEO title="Translation Quality via API - lexiQA" />
      <SEO description="API-based QA tool that can be seamlessly integrated into your localization workflow. Test it with your own data!" />

      {/* <!--======TEAM PART START======--> */}

      { PAGEDATA.api.map( ( val, index ) => {
        const isEven = index % 2 === 0
        return <ApiList isEven={ isEven } data={ val } key={ val.heading } />
      } ) }

      {/* <!--======TEAM PART END======--> */}

      {/* <!--====== CALL TO ACTION START======--> */}
      <section className="call-to-action-area bg_cover bg-light pt-115 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="call-action">
                <div className="section-title text-center section-title-white">
                  <h5 className="title mb-3">
                    <span>API documentation page</span>
                  </h5>
                  <h6 className="sub-title mb-5">
                    Test the lexiQA API with your own data. Please contact us in order to receive API credentials.
                  </h6>
                </div>
              </div>
              <div className="text-center">
                <a className="main-btn btn-primary" rel="noopener noreferrer" href="/contact">CONTACT US</a>
              </div>
            </div>
          </div>
          {/* <!-- row --> */}
        </div>
        {/* <!-- container --> */}
      </section>
      {/* <!--====== CALL TO ACTION END======--> */}

      <Footer />
    </PageWrapper>
  )
}

export default ApiIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
