import React from "react"
import "./ApiList.scss"

function ApiList(props) {
  return (
    <section className={"apilist-component pt-80 pb-50 " + ( props.isEven ? "bg-white" : "bg-light" ) }>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 text-center main-title">
            <h2>{props.data.heading}</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {props.data.sub.map(({ title, method, url, text }) => {

            const id = title.replace(/-/g,"-").toLowerCase();

            return (
              <div className="col-md-9" key={title}>
                <div className="single-service-2 service-6 mt-30">
                  <div className="service-content media-body api-body mt-5">
                    <h4 id={ id } className="title api-title">
                      {title}
                      {/* <a href={ "#" + id } aria-hidden="true" className="api-header-anchor">#</a> */}
                    </h4>
                    <p className="text url">
                      <span className={ "btn btn-method mr-3 " + ( method === "GET" ? "btn-method-get" : "btn-method-post")  }>{method}</span>
                      {url}
                    </p>
                    <p className="text api-text mt-2">{text}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ApiList
